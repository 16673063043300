import React from "react";
import styles from './ProfilePage.module.scss'

const ProfilePage = () => {

    return (
        <></>
    )
}

export default ProfilePage;